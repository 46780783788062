views.basketSummary = new Vue({
    el: '#basket-summary',
    data: {
        items: get('basket.items', {}),
        total: get('basket.total', 0),
        rows: get('basket.rows', 0),
        beatRoute: get('basket.beatRoute'),
        areaRoute: get('basket.areaRoute'),
        destroyRoute: get('basket.destroyRoute')
    },
    computed: {

    },
    methods: {
        buildAreaRoute: function (slug) {
            return this.areaRoute + '/' + slug;
        },
        buildBeatRoute: function (areaSlug, beatSlug) {
            return this.beatRoute + '/' + areaSlug + '/' + beatSlug;
        },
        buildDestroyRoute: function (rowid) {
            // Unable to route this without returning a route for each item.
            return '/basket/' + rowid + '/remove';
        },
        objectLength: function (object) {
            return Object.keys(object).length;
        }
    }
});