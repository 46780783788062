$.fn.serializeObject = function()
{
    var o = {};
    var a = this.serializeArray();
    $.each(a, function() {
        if (o[this.name] !== undefined) {
            if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
            }
            o[this.name].push(this.value || '');
        } else {
            o[this.name] = this.value || '';
        }
    });
    return o;
};

function updateBasket() {
    $.getJSON('/basket', function(response) {
        views.basketSummary.items = response.items;
        views.basketSummary.total = response.total;
        views.basketSummary.rows = response.rows;
    });
}

toastr.options.progressBar = true;
toastr.options.timeOut = 10000;
toastr.options.extendedTimeOut = 10000;


$('.banner-header:not(.no-affix)').affix({
    offset: {
        top: function() {
            if($('.navbar-obs-admin').length) {
                return $('.banner-header').height() - $('.navbar-obs-admin').height() - 100 + 27;
            } else {
                return $('.banner-header').height() - 100 + 27;
            }
        }
    }
});

$('.banner-images').affix({
    offset: {
        top: function() {
            if($('.navbar-obs-admin').length) {
                return $('.banner-header').height() - $('.navbar-obs-admin').height() - 100;
            } else {
                return $('.banner-header').height() - 100;
            }
        }
    }
});

$('.buy-form').submit(function(){
    $(this).find('i.fa-spinner').removeClass('hide');
    $(this).find('i.fa-shopping-basket').addClass('hide');
    $(this).find('button').attr('disabled','disabled');

    $.ajax(get('buyUrl'), {
        data: $(this).serializeArray(),
        method: 'POST'
    }).done(function(response) {
        if (response.success) {
            if (window.location.pathname == '/basket') {
                location.reload();
            } else {
                setTimeout(function () {
                    updateBasket();
                    swal({
                        title: 'Added to Basket',
                        timer: 1500,
                        showConfirmButton: false,
                        type: 'success',
                    });
                }, 500);
            }
        } else if (typeof response.message !== 'undefined') {
            toastr.error(response.message);
        } else {
            toastr.error('An error has occurred, please contact the administration team.');
        }
    }).error(function() {
        toastr.error('An error has occurred, please contact the administration team.');
    }).always(function() {
        setTimeout(function() {
            $(this).find('i.fa-spinner').addClass('hide');
            $(this).find('i.fa-shopping-basket').removeClass('hide');
            $(this).find('button').removeAttr('disabled');
        }.bind(this), 500);
    }.bind(this));

    return false;
});

$('.farlows-header').click(function() {
    window.open('http://www.farlows.co.uk/charles-farlow-trophy', '_blank');

});

$(".notice-image-list a").fancybox({
    fitToView : false,
    autoSize: false,
    height: 'auto',
    width: '100%',
    maxWidth: 960,
    maxHeight: 500
});

if($(".simple-infinite-scroll-list").length) {
    //assuming only ever one per page
    var simpleInfiniteScroll = {
        loadingAjax: false,
        currentPage: 1,
        endOfData: false,
        pixelBuffer: 200, //how many pixels ahead of the bottom page we want to be.
        init: function() {
            var that = this;
            $(window).scroll(function(e) {
                that.checkScrollPosition();
            });
            //incase the page is already scrolled.
            that.checkScrollPosition();
        },
        checkScrollPosition: function() {

            var screen_bottom = $(window).scrollTop() + $(window).height();
            var threshold = $(document).height() - $('footer').height() -  this.pixelBuffer;

            if(screen_bottom >= threshold) {
                this.loadNextData();
            }
        },
        loadNextData: function() {
            if(!this.loadingAjax && !this.endOfData) {
                this.loadingAjax = true;
                this.currentPage += 1;
                
                var url = window.location.pathname;
                var that = this;

                $.ajax({
                    type: 'GET',
                    url: url,
                    data: {page: this.currentPage},
                    success: function(response) {
                        $(".simple-infinite-scroll-list").append(response);
                        that.loadingAjax = false;
                    },
                    error: function(response) {
                        that.endOfData = true;
                        that.loadingAjax = false;
                    }
                });
            }
        },
    };

    simpleInfiniteScroll.init();
}
